<template>
  <a class="dxa-link" :style="className" :href="href" :target="target">{{
    text
  }}</a>
</template>

<script>
export default {
  name: "DxaLink",
  props: {
    href: {
      type: String,
      default: "#",
    },
    target: {
      type: String,
      default: "_blank",
    },
    text: {
      type: String,
      default: "",
    },
    size: {
      type: String,
      default: "medium",
    },
  },
  created() {
    this.className = this.getLinkSize();
  },
  methods: {
    getLinkSize() {
      return this.checkSize(this.size);
    },
    checkSize(size) {
      const sizes = {
        small: "font-size: 10px",
        medium: "font-size: 12px",
        large: "font-size: 16px",
      };
      return sizes[size] || "font-size: 16px";
    },
  },
};
</script>

<style scoped>
.dxa-link {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  letter-spacing: 0.15px;
  color: var(--primary);
  margin-left: 5px;

  text-decoration: none;
}

a.dxa-link:hover {
  color: var(--primary-hover);
  text-decoration: underline;
}

a.dxa-link:active {
  color: var(--primary-focus);
  text-decoration: underline;
}
</style>
